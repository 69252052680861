import React, { useState } from "react";
import LogoNav from "../../assets/logo.svg";
import {
  CloseIcon,
  Container,
  Links,
  Logo,
  MenuIcon,
  MobileIcon,
  Nav,
  NavItem,
  NavLinks,
} from "./styles";

export const Navbar: React.FC = () => {
  const [click, setClick] = useState(false);

  const handleClick = () => {
    setClick(!click);
    if (!click) {
      document.body.classList.add("menu-open");
    } else {
      document.body.classList.remove("menu-open");
    }
  };

  return (
    <Container>
      <Nav>
        <Logo
          src={LogoNav}
          alt="Logo do The best açaí"
          onClick={() => (window.location.href = "/")}
        />

        <MobileIcon onClick={handleClick}>
          {click ? <CloseIcon /> : <MenuIcon />}
        </MobileIcon>

        <Links open={click} onClick={handleClick}>
          <NavItem>
            <NavLinks href="#nossa-historia">Nossa história</NavLinks>
          </NavItem>
          <NavItem>
            <NavLinks href="#nossos-produtos">Nossos produtos</NavLinks>
          </NavItem>
          <NavItem>
            <NavLinks href="#galeria">Galeria</NavLinks>
          </NavItem>
          <NavItem>
            <NavLinks href="#principios">Código de cultura</NavLinks>
          </NavItem>
          <NavItem>
            <NavLinks href="#trabalhe-conosco">Trabalhe conosco</NavLinks>
          </NavItem>
        </Links>
      </Nav>
    </Container>
  );
};
