import styled, { css } from "styled-components";
import { BarGraphRounded } from "../../styles/icons";

export const Container = styled.div`
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  padding: 1rem 4rem;
  display: flex;
  align-items: center;

  @media screen and (max-width: 728px) {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
`;

export const ContainerLeft = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  margin-right: 5rem;

  @media screen and (max-width: 728px) {
    margin: 0 0 1rem;
  }
`;

export const ContainerGeneral = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
`;

export const Timeline = styled.img`
  width: 80%;
  margin-bottom: 5rem;
  z-index: 999;

  @media screen and (max-width: 1200px) {
    width: 90%;
  }

  @media screen and (max-width: 728px) {
    margin-bottom: 3rem;
  }
`;

export const ContentNumbers = styled.div`
  display: flex;
  align-items: center;
  span {
    font-size: 1rem;
    letter-spacing: 1.8px;
    font-weight: lighter;
    color: #00861d;
    margin-left: 13px;
  }
`;

export const CardContent = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  padding: 2rem 4rem;
  margin: 2rem 0 4rem 0;
  h3 {
    font-size: 1.5rem;
    margin-bottom: 12px;
    font-weight: lighter;
  }

  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  @media screen and (max-width: 728px) {
    padding: 1rem 2rem;
    margin: 0;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding: 1.75rem 0 1.75rem 2.5rem;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15),
    0px 1px 2px 0px rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  text-align: center;
  width: 30rem;
  height: 15rem;
  z-index: 999;
  background-color: var(--white);

  p {
    font-size: 4rem;
    font-weight: 900;
    letter-spacing: 6.744px;
    margin-bottom: 12px;
    color: var(--radiant-yellow);

    @media screen and (max-width: 1400px) {
      font-size: 3.1rem;
    }
    @media screen and (max-width: 1200px) {
      font-size: 2rem;
    }
  }

  @media screen and (max-width: 1700px) {
    width: 25rem;
    padding: 1rem 0 1rem 1rem;

    h3 {
      text-align: initial;
    }
  }

  @media screen and (max-width: 1400px) {
    width: 22rem;
  }
  @media screen and (max-width: 1200px) {
    width: 17rem;
  }

  @media screen and (max-width: 1024px) {
    width: 30rem;
    margin-bottom: 1rem;
  }

  @media screen and (max-width: 500px) {
    width: 22rem;
    height: 12rem;
    margin-bottom: 1rem;
  }

  .box-content {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    img {
      @media screen and (max-width: 1800px) {
        width: 35%;
      }
    }
  }

  .box-content-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ImgLeft = styled.img`
  width: 100%;
  object-fit: cover;
`;

export const ContainerRight = styled.div`
  width: 100%;

  p {
    font-size: 1.1rem;
    line-height: 1.6rem;
    font-weight: lighter;
    z-index: 999;
    position: relative;

    @media screen and (max-width: 728px) {
      margin-bottom: 1rem;
    }
  }
`;

export const DetailImg = styled.img`
  position: absolute;
  width: 35%;
  right: 0;
  top: 20%;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

const IconCSS = css`
  width: 5rem;
  height: 5rem;
  margin-bottom: 1rem;
  fill: var(--radiant-yellow);

  @media screen and (max-width: 575px) {
    width: 4rem;
    height: 4rem;
  }
`;

export const Graph = styled(BarGraphRounded)`
  ${IconCSS};
`;
