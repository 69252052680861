import styled from "styled-components";

export const ContainerGalery = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 100%;

  h2 {
    text-align: center;
    margin: 3.5rem 0;

    @media screen and (max-width: 728px) {
      margin: 1.5rem 0;
    }
  }
`;

export const ImagesContent = styled.div`
  display: grid;
  grid-template-columns: 2fr repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 5px;
  grid-row-gap: 5px;

  @media screen and (max-width: 768px) {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 5px;
    grid-row-gap: 5px;
  }

  img {
    width: 100%;
    height: 100%;
    max-height: 20rem;
    object-fit: cover;
    z-index: 999;
    filter: contrast(50%) saturate(2);
    cursor: pointer;

    @media screen and (max-width: 768px) {
      width: 100%;
      height: 12rem;
    }
  }
`;


