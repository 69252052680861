import React from "react";
import ImageStore from "../../assets/images/acaitradicional.png";
import Detalhe from "../../assets/detail2.svg";

import {
  ContainerLeft,
  ContainerRight,
  ImgLeft,
  Container,
  ContainerGeral,
  DetailImg,
} from "./styles";

export const NossosProdutos = () => {
  return (
    <ContainerGeral>
      <DetailImg src={Detalhe} alt="Detalhe" />
      <Container id="nossos-produtos">
        <ContainerLeft>
          <h2>Nossos Produtos</h2>

          <p>
            A ampla linha de açaís e sorvetes possuem receitas próprias,
            desenvolvidas para promover a melhor experiência aos nossos
            consumidores. A busca por inovação nos motiva a desenvolver os
            melhores sabores, cores, texturas e processos.
          </p>
        </ContainerLeft>

        <ContainerRight>
          <ImgLeft src={ImageStore} alt="Imagem da fábrica" />
        </ContainerRight>
      </Container>
    </ContainerGeral>
  );
};
