import React from "react";
import {
  AscentPersonIcon,
  Container,
  Graph,
  HandsShakeIcon,
  PeopleCommunityIcon,
  PrincipleIcon,
  PrinciplesCards,
  PrinciplesCardsContainer,
  PrinciplesIconContainer,
  PrinciplesText,
  PrinciplesTextContainer,
  PrinciplesTitle,
  SettingsIcon,
  Star,
  TimerIcon,
  TrophyIcon,
  UserFlagIcon,
  UserGraduateIcon,
} from "./styles";
import ScrollFadeSection from "../../page/ScrollFadeSection";

export const Principles = () => {
  const principios = [
    {
      id: 1,
      icon: <Graph />,
      title: "1. Faça o seu melhor",
      text: "Em todas as ações do seu dia, e em tudo o que você faz, você se pergunta se está fazendo o seu melhor? Seja com amigos, família, religião e principalmente trabalho, se cobre e faça o seu melhor! É assim que você se desenvolve! Somos uma empresa que estamos em melhoria contínua e mudando sempre, mas uma coisa que nunca irá mudar é que sempre vamos fazer o nosso MELHOR! PROGRESSO = Disciplina + Trabalho Duro!",
    },
    {
      id: 2,
      icon: <Star />,
      title: "2. Faça o melhor para o cliente",
      text: "Fazer o nosso melhor para o cliente sempre nos colocará na direção certa. Toda vez em que você tiver alguma dúvida sobre sua postura com o cliente, pergunte-se: Essa é a maneira que eu gostaria de ser atendido? Essa é postura que eu gostaria que as pessoas que eu mais amo, fossem atendidas? Basta refletir sobre isso. Existe apenas uma pessoa que pode tirar qualquer um do jogo, essa pessoa é o CLIENTE!",
    },
    {
      id: 3,
      icon: <TrophyIcon />,
      title: "3. Sucesso NÃO aceita preguiça",
      text: "Não toleramos vagabundagem! E o que isso significa? Errar faz parte do processo de desenvolvimento, todos erramos. Mas, não podemos gostar dessa dor. Sinta-a uma vez, para não acontecer mais. Não aceitamos pessoas que só culpam os outros e não olham os próprios erros. Não tenha preguiça com você. Adiar e postergar as coisas é adiar o seu desenvolvimento, seus sonhos e o nosso progresso. Faça o quanto antes puder. Organize, se planeje e EXECUTE.",
    },
    {
      id: 4,
      icon: <UserGraduateIcon />,

      title: "4. Saiba sua posição",
      text: "Em toda conversa dentro da empresa, pergunte-se naquele momento se você é um professor ou aluno no assunto. Saiba se posicionar e respeitar as posições de conhecimento. Tenha ATITUDE para mostrar aquilo que você domina, mas também tenha HUMILDADE, para ouvir e aprender sempre que necessário.",
    },
    {
      id: 5,
      icon: <PeopleCommunityIcon />,
      title: "5. Coletivismo > individualismo",
      text: "O talento vence jogos, mas só o trabalho em equipe vence campeonatos. A função dos membros da equipe é sempre cooperar com o time. O individualismo pode ser visto na competitividade saudável entre as pessoas. É o que chamamos de COOPETIÇÃO. Cooperar + Competir para melhorar!",
    },
    {
      id: 6,
      icon: <HandsShakeIcon />,
      title: "6. Família e time",
      text: "Respeito, convívio, amizade e bons relacionamentos. Em alguns casos passamos mais tempo com as pessoas no trabalho do que com pessoas de nossa família. Isso se dá porque criamos laços e vínculos com as pessoas, e isso é muito bom. Quem que não gosta de trabalhar com pessoas que preenchem nosso dia e deixam ele melhor? Seja essa pessoa que sempre melhora o dia de alguém e quer o melhor de quem está ao seu lado!",
    },
    {
      id: 7,
      icon: <AscentPersonIcon />,
      title: "7. Stay hungry, stay foolish",
      text: "Acreditamos em melhoria e temos um sonho grande! Odiamos pessoas conformadas e preguiçosas em sua zona de conforto. Entenda, existem milhares de coisas para melhorar a sua vida e a vida de alguém. Você precisa ter humildade para sempre aprender e continuar faminto para sempre querer mais. Entenda novamente, querer mais, querer melhor, não é só em relação a dinheiro, abra a cabeça e queira mais e melhor do mundo em que você vive!",
    },
    {
      id: 8,
      icon: <UserFlagIcon />,
      title: "8. Falou, cumpriu",
      text: "Responsabilidade e Transparência. A sua palavra deve ter o poder de valer mais do que qualquer contrato ou documento. Tenha lealdade com a sua palavra e valorize o tempo do outro. Dê prioridade no que importa, não deixe seu tempo ao léu para os outros decidam.",
    },
    {
      id: 9,
      icon: <TimerIcon />,
      title: "9. Velocidade > perfeição",
      text: "Nossa empresa sempre crescerá muito e rápido ao mesmo tempo e para continuar entregando precisamos sempre lembrar que velocidade vale mais do que perfeição. FEITO na maioria das vezes é melhor que perfeito. Você precisa ser ágil e rápido, e isso não significa que tenha que entregar algo sem valor. Lembre-se, se planejar, saber priorizar e fazer o seu melhor será a fórmula para entregar aquilo que precisa ser feito!",
    },
    {
      id: 10,
      icon: <SettingsIcon />,
      title: "10. Performance no caórdico",
      text: "Caórdico não é caótico. Caórdico é quando há caos e ordem simultaneamente, na medida certa, onde a empresa cresce de forma acelerada. Manter a performance nesse estado é como jogar um jogo com mais dificuldade, no modo expert, mas nesse caso, com mais resultados! 🚀",
    },
  ];

  return (
    <Container id="principios">
      <h2>Código de cultura</h2>
      <div>
        <PrinciplesCardsContainer>
          {principios.map((cards) => (
            <ScrollFadeSection>
              <PrinciplesCards key={cards.id}>
                <PrinciplesIconContainer>
                  <PrincipleIcon>{cards.icon}</PrincipleIcon>
                </PrinciplesIconContainer>
                <PrinciplesTextContainer>
                  <PrinciplesTitle>{cards.title}</PrinciplesTitle>
                  <PrinciplesText>{cards.text}</PrinciplesText>
                </PrinciplesTextContainer>
              </PrinciplesCards>
            </ScrollFadeSection>
          ))}
        </PrinciplesCardsContainer>
      </div>
    </Container>
  );
};
