import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--radiant-yellow);
  width: 100%;
  padding: 3.5rem 8rem 1rem;

  @media screen and (max-width: 1100px) {
    padding: 1.5rem 3rem 1rem;
  }
`;

export const ContainerLinks = styled.div`
  display: flex;
  padding: 0 0 2rem;

  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media screen and (max-width: 1024px) {
    padding: 0 0 1rem;
  }

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }
`;

export const Links = styled.ul`
  display: flex;

  li {
    margin-right: 3rem;

    @media screen and (max-width: 1024px) {
      margin-bottom: 0.5rem;

      :last-child {
        margin-bottom: 0;
      }
    }
  }

  a {
    cursor: pointer;
    outline: none;
    color: var(--white);
    transition: all 0.3s ease-in-out;
    text-decoration: none;

    &:hover {
      opacity: 40%;
    }
  }

  @media screen and (max-width: 1024px) {
    display: contents;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  @media screen and (max-width: 500px) {
    display: contents;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;

export const LinkToPrivacy = styled(Link)`
  cursor: pointer;
  outline: none;
  color: var(--white);
  transition: all 0.3s ease-in-out;
  text-decoration: none;

  &:hover {
    opacity: 40%;
  }
`;

export const Line = styled.hr`
  border: 0;
  border-bottom: 1px solid var(--white);
  width: 100%;
`;

export const ContainerLogo = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem 0 0;
  color: var(--white);
  @media screen and (max-width: 1100px) {
    padding: 1rem 0 0;
  }
`;

export const LogoAmadelli = styled.img`
  display: flex;
  width: 12%;

  @media screen and (max-width: 768px) {
    width: 30%;
  }
`;