import styled from "styled-components";
import { MenuCenter, Fix } from "../../styles/icons";

interface IMobileProps {
  open: boolean;
}

export const Container = styled.div`
  display: flex;
  height: 5rem;
  max-width: 100%;

  @media screen and (max-width: 728px) {
    overflow: hidden;
  }
`;

export const Nav = styled.nav`
  background: var(--white);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-size: 0.9rem;
  width: 50%;
  padding: 0 5rem;

  @media screen and (max-width: 728px) {
    padding: 0 2rem;
    height: 8vh;
    width: 100%;
    position: absolute;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
`;

export const Logo = styled.img`
  cursor: pointer;
  display: flex;

  @media screen and (max-width: 728px) {
    width: 50%;
    display: flex;
    justify-content: flex-start;
  }
`;

export const Links = styled.ul<IMobileProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
  text-align: center;
  justify-content: center;
  -webkit-overflow-scrolling: touch;

  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: fixed;
    align-items: center;
    width: 100%;
    height: 92vh;
    position: absolute;
    top: 100%;
    opacity: 1;
    transition: all 0.5s ease;
    background-color: rgba(128, 128, 128, 0.5);
    backdrop-filter: blur(10px);
    padding: 1rem 0;
    overflow: hidden;
    right: ${({ open }) => (open ? 0 : "100%")};
    display: ${({ open }) => (open ? "flex" : "none")};
  }

  @media screen and (max-height: 400px) {
    height: 91vh;
  }
`;

export const NavItem = styled.li`
  display: flex;
  align-items: center;
  font-size: 2rem;
  margin-bottom: 1.5rem;
  cursor: pointer;
  overflow: hidden;
`;

export const NavLinks = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 100%;
  margin-left: 40px;
  color: var(--dark-tone-ink);
  font-size: 1.5rem;
  letter-spacing: 0rem;
  position: relative;
  text-transform: uppercase;

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    color: var(--dark-tone-ink);
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    transform: scaleX(0);
    background-color: var(--dark-tone-ink);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  &:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  @media screen and (max-width: 1024px) {
    justify-content: center;
    padding: 2rem 0 1rem;
    width: 100%;
    margin-left: 0;
    color: var(--white);
    font-size: 1rem;

    &:hover {
      color: var(--dark-tone-ink);
      transition: all 0.3s ease;
    }

    &.active {
      height: 2rem;
    }
  }
`;

export const MobileIcon = styled.div`
  display: none;
  @media screen and (max-width: 1024px) {
    display: block;
    position: absolute;
    top: -25px;
    right: -25px;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const MenuIcon = styled(MenuCenter)`
  width: 3rem;
  height: 3rem;
  color: var(--radiant-yellow);
`;

export const CloseIcon = styled(Fix)`
  width: 3rem;
  height: 3rem;
  color: var(--radiant-yellow);
`;
