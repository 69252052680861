import styled from "styled-components";

export const Container = styled.section`
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  padding: 1rem 4rem;
  display: flex;
  align-items: center;
  background-color: var(--white);


  @media screen and (max-width: 728px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem 2rem;
  }
`;

export const ContainerLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-right: 5rem;

  h2{
    z-index: 999;
  }

  p {
    font-size: 1.1rem;
    line-height: 1.6rem;
    font-weight: lighter;
    z-index: 999;

    @media screen and (max-width: 728px) {
      margin-bottom: 1rem;
    }
  }

  @media screen and (max-width: 728px) {
    margin-right: 0;
  }
`;

export const ImgLeft = styled.img`
  width: 100%;
`;

export const ContainerGeral = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

`;

export const ContainerRight = styled.div`
  width: 100%;
  object-fit: cover;
`;

export const Detail = styled.img`
  margin: 5% 0;
  width: 40%;
`;

export const DetailImg = styled.img`
  position: absolute;
  left: 0;
  top: 5%;
  width: 35%;
  z-index: 99;

  @media screen and (max-width: 1024px){
    display: none;
  }
`;
