import styled, { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    html {
        scroll-behavior: smooth;
    }    

    html, body, #root {
        background: var(--white);
        max-width: 100%;
        max-height: 100%;
        touch-action: manipulation;
        width: 100%;
        height: 100%;
        font: 400 1rem "Roboto", sans-serif;
        -webkit-text-size-adjust: none;
        -webkit-font-smoothing: antialiased;
    }

    body {
        line-height: 1;
    }

    ul, ol {
        list-style: none;
    }

    a {
        text-decoration: none;
    }

    h2{
        margin-bottom: 1rem;
        @media screen and (max-width: 728px) {
          text-align: center;
      }
    }

    /* body.menu-open {
        overflow: hidden;
    } */
    
    .rec .rec-dot {
        margin-top: 2rem;
        box-shadow: none;
        border: 1px solid var(--radiant-yellow);
        width: 18px;
        height: 18px;

        @media screen and (max-width: 1024px) {
        width: 10px;
        height: 10px;
        }
    }

    .rec .rec-dot_active {
        background-color: var(--radiant-yellow);
    }

    ::-webkit-scrollbar {
        width: 8px;
    }

    ::-webkit-scrollbar-track {
        background-color: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
        background-color: var(--radiant-yellow);
        border-radius: 10px;
    }

    :root{
        --white: #fff;
        --soft-white: #f0f0f0;
        --dark-tone-ink: #121212;
        --radiant-yellow: #f6a020;
        --gray: #CBD0DA;
        --neutral-gray: #6F6C90;
        --soft-black: #170F49;
    }
`;

export const Container = styled.div`
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  padding: 1rem 4rem;
  display: flex;
  align-items: center;

  @media screen and (max-width: 728px) {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
`;