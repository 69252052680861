import React from "react";
import GlobalStyles from "./styles/globalStyles";
import Router from "./routes/router";

function App() {
  return (
    <div>
      <GlobalStyles />
      <Router />
    </div>
  );
}

export default App;
