import styled, { css } from "styled-components";
import {
  BarGraphRounded,
  UserStart,
  Trophy,
  UserGraduate,
  PeopleCommunity,
  HandsShake,
  AscentPerson,
  UserFlag,
  Timer,
  Settings,
} from "../../styles/icons";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 4rem;
  align-items: center;
  width: 100%;

  div {
    margin: 0 auto;
    margin-bottom: 1rem;
  }

  h2 {
    text-align: center;
    margin: 1.5rem 0;
  }

  @media screen and (max-width: 728px) {
    padding: 1rem 2rem;
  }
`;

export const PrinciplesCardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  grid-row-gap: 2rem;
  margin: 0 auto;

  @media screen and (max-width: 690px) {
    grid-template-rows: auto;
    grid-template-columns: auto;
  }
`;

export const PrinciplesCards = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
  padding: 2rem;
  height: 90%;
  margin: 0.5rem 0;
  background-color: var(--white);
  border: 1px solid var(--soft-white);
  border-radius: 10px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 1rem;
    height: 100%;
  }
`;

export const PrincipleTextContainer = styled.div`
  padding: 0%.5rem 1rem;
`;

export const PrinciplesIconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const PrincipleIcon = styled.div`
  padding: 0.2rem;
  height: 5rem;
  background-color: var(--white);
`;

export const PrinciplesTitle = styled.h2`
  font-size: 1.5rem;
  letter-spacing: 0;
  margin-bottom: 1rem;

  @media screen and (max-width: 575px) {
    text-align: center;
    font-size: 1.1rem;
  }
`;

export const PrinciplesTextContainer = styled.div`
  padding: 0.5rem 1rem;
`;

export const PrinciplesText = styled.p`
  font-size: 1rem;
  line-height: 1.8rem;
  color: var(--neutral-gray);

  @media screen and (max-width: 575px) {
    text-align: justify;
  }
`;

export const ImageCard = styled.img`
  width: 5rem;
  height: 5rem;
`;

const IconCSS = css`
  width: 5rem;
  height: 5rem;

  color: var(--radiant-yellow);

  @media screen and (max-width: 575px) {
    width: 4rem;
    height: 4rem;
  }
`;

export const Graph = styled(BarGraphRounded)`
  ${IconCSS};
`;

export const Star = styled(UserStart)`
  ${IconCSS};
`;
export const TrophyIcon = styled(Trophy)`
  ${IconCSS};
`;
export const UserGraduateIcon = styled(UserGraduate)`
  ${IconCSS};
`;
export const PeopleCommunityIcon = styled(PeopleCommunity)`
  ${IconCSS};
`;
export const HandsShakeIcon = styled(HandsShake)`
  ${IconCSS};
`;
export const AscentPersonIcon = styled(AscentPerson)`
  ${IconCSS};
`;
export const UserFlagIcon = styled(UserFlag)`
  ${IconCSS};
`;
export const TimerIcon = styled(Timer)`
  ${IconCSS};
`;
export const SettingsIcon = styled(Settings)`
  ${IconCSS};
`;
