import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

interface SectionProps {
  delay: number;
  isVisible: boolean;
}

const Section = styled.section<SectionProps>`
  opacity: 0;
  animation: ${({ isVisible }) => (isVisible ? fadeIn : 'none')} 1s ease-in-out forwards;
  animation-delay: ${({ delay }) => delay};
`;

interface ScrollFadeSectionProps {
  children: React.ReactNode;
}

const ScrollFadeSection: React.FC<ScrollFadeSectionProps> = ({ children }) => {
  const sectionRef = useRef<HTMLElement>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [hasAnimated, setHasAnimated] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (!hasAnimated && sectionRef.current) {
        const top = sectionRef.current.getBoundingClientRect().top;
        const isVisible = top < window.innerHeight * 0.8; 
        setIsVisible(isVisible);
        if (isVisible) {
          setHasAnimated(true);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [hasAnimated]);

  return <Section ref={sectionRef} isVisible={isVisible} delay={0}>{children}</Section>;
};

export default ScrollFadeSection;