import React from "react";
import ImageLink from "../../assets/images/foto_fabrica.png";
import ImageLinkBox from "../../assets/images/foto_fabrica.png";
import { ButtonElement, Container, Image } from "./styles";

export const TrabalheConosco = () => {
  return (
    <>
      <Container id="trabalhe-conosco">
        <div>
          <h2>
            Quer <strong>trabalhar</strong> conosco?
          </h2>
          <span>Alavanque a sua carreira com um time de sucesso!</span>
          <br />
        </div>

        <ButtonElement
          target="_blank"
          rel="noreferrer"
          href="https://grupothebest.gupy.io"
        >
          Veja nossas vagas
        </ButtonElement>
      </Container>
      <Image src={ImageLink} alt="Imagem dos trabalhadores da Amadelli" />
    </>
  );
};
