import React from "react";
import { Navbar } from "../../components/Navbar";
import { Banner } from "../../components/Banner";
import { NossaHistoria } from "../../components/NossaHistoria";
import { NossosProdutos } from "../../components/NossosProdutos";
import { Galeria } from "../../components/Galeria";
import { Principles } from "../../components/Principios";
import { Footer } from "../../components/Footer";
import { TrabalheConosco } from "../../components/TrabalheConosco";
import { Depoimentos } from "../../components/Depoimentos";
import { ScrollToTopButton } from "../../components/Scroll";
import ScrollFadeSection from "../ScrollFadeSection";

export const Home = () => {
  return (
    <div>
      <Navbar />
      <Banner />

      <ScrollFadeSection>
        <NossaHistoria />
      </ScrollFadeSection>

      <ScrollFadeSection>
        <NossosProdutos />
        <Galeria />
      </ScrollFadeSection>

      <ScrollFadeSection>
        <Principles />
      </ScrollFadeSection>

      {/* <ScrollFadeSection>
        <Depoimentos />
      </ScrollFadeSection> */}

      <ScrollFadeSection>
        <TrabalheConosco />
      </ScrollFadeSection>

      <Footer />
      <ScrollToTopButton />
    </div>
  );
};
