import React from "react";
import VideoBanner from '../../assets/video.mp4'
import { BannerContainer, BackgroundVideo } from "./styles";

export const Banner: React.FC = () => {
  return (
    <BannerContainer>
      <BackgroundVideo autoPlay loop muted playsInline>
        <source src={VideoBanner} type="video/mp4" />
      </BackgroundVideo>
    </BannerContainer>
  );
};
