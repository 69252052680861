import React from "react";
import ImageStore from "../../assets/images/fabrica.png";
import Box from "../../assets/box.svg";
import Detalhe from "../../assets/detail.svg";
import TimelineElement from "../../assets/timeline.svg";
import TimelineElementMobile from "../../assets/TimelineMobile.svg";

import {
  Card,
  CardContent,
  Container,
  ContainerGeneral,
  ContainerLeft,
  ContainerRight,
  ContentNumbers,
  DetailImg,
  ImgLeft,
  Timeline,
} from "./styles";
import ScrollFadeSection from "../../page/ScrollFadeSection";

export const NossaHistoria: React.FC = () => {
  return (
    <ContainerGeneral>
      <DetailImg src={Detalhe} alt="Detalhe" />
      <CardContent>
        <ScrollFadeSection>
          <Card>
            <h3>Toneladas produzidas</h3>
            <ContentNumbers>
              <p>400 ton</p>

              <span>+ 27%</span>
            </ContentNumbers>
            <h3>no ultimo mês</h3>
          </Card>
        </ScrollFadeSection>

        <ScrollFadeSection>
          <Card className="box">
            <div className="box-content">
              <img src={Box} alt="box" />

              <div className="box-content-left">
                <h3>Caixas produzidas</h3>
                <ContentNumbers>
                  <p>45 mil</p>
                </ContentNumbers>
                <h3>no ultimo mês</h3>
              </div>
            </div>
          </Card>
        </ScrollFadeSection>

        <ScrollFadeSection>
          <Card>
            <h3>Área em expansão</h3>
            <ContentNumbers>
              <p>12 mil</p>

              <span>e crescendo!</span>
            </ContentNumbers>
            <h3>metros quadrados</h3>
          </Card>
        </ScrollFadeSection>
      </CardContent>

      <Container id="nossa-historia">
        <ContainerLeft>
          <ImgLeft src={ImageStore} alt="Imagem da fábrica" />
        </ContainerLeft>

        <ContainerRight>
          <h2>A nossa história</h2>

          <p>
            Somos a Amadelli, fábrica e distribuidora dos produtos The Best
            Açaí, localizada em Ibiporã, no Paraná. Nossa primeira fábrica foi
            criada em 2019 a partir da necessidade de padronização e qualidade
            dos produtos oferecidos pelas lojas The Best Açaí, dessa forma,
            teríamos mais controle em cima da qualidade oferecida e logística,
            atualmente, produzimos 20 toneladas de açaí e sorvete diariamente. A
            tecnologia, seleção da matéria prima, o controle da produção e a
            logística própria garantem a qualidade e o sabor incomparável dos
            produtos encontrados em todas as lojas The Best Açaí. Com
            investimentos constantes em pessoas, maquinários, espaço físico,
            veículos, gestão, processos, transportes e manutenção.
          </p>
        </ContainerRight>
      </Container>

      {window.outerWidth > 728 ? (
        <Timeline src={TimelineElement} alt="Linha do tempo amadelli" />
      ) : (
        <Timeline src={TimelineElementMobile} alt="Linha do tempo amadelli" />
      )}
    </ContainerGeneral>
  );
};
