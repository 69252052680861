import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo_branca.svg";
import {
  Container,
  ContainerLinks,
  ContainerLogo,
  FooterContainer,
  Line,
  Links,
  LogoAmadelli,
} from "./styles";

export const Footer = () => {
  return (
    <Container>
      <FooterContainer>
        <ContainerLinks>
          <Links>
            <li>
              <a href="#nossa-historia">Sobre a Amadelli</a>
            </li>
            <li>
              <Link to="/privacidade">Privacidade de dados</Link>
            </li>
          </Links>
        </ContainerLinks>

        <Line />
        <ContainerLogo>
          <div>
            Assessoria de Imprensa <br />
            Julia Nascimento
            <br />
            comunicacao@thebestacai.com.br
          </div>
          <LogoAmadelli src={Logo} alt="Logo amadelli" />
        </ContainerLogo>
      </FooterContainer>
    </Container>
  );
};
