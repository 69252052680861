import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  height: 100%;
  padding: 1rem 4rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--white);

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 3.5rem 0.5rem 0;

    @media screen and (max-width: 728px) {
      margin: 1.5rem 0;
    }
  }

  h2 {
    font-size: 1.5rem;
    text-align: center;
  }

  span {
    font-size: 0.9rem;
    color: var(--gray);
    text-align: center;
  }

  @media screen and (max-width: 728px) {
    padding: 1rem 2rem;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 20rem;
  object-fit: cover;
  border-radius: 2px;
  margin-bottom: -5px;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 10rem;
  }
`;

export const ButtonElement = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--radiant-yellow);
  color: var(--white);
  border: 0;
  border-radius: 5px;
  padding: 1rem;
  margin-top: 3rem;
  font-size: 1.25rem;
  text-decoration: none;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  &:hover {
    animation: pulse 1s infinite;
    transition: 0.3s;
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    70% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1);
    }
  }
`;
