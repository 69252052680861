import { ContainerGalery, ImagesContent } from "./styles";

export const Galeria = () => {
  const Images = [
    {
      id: 1,
      url_image: require("../../assets/images/ninho.png"),
    },
    {
      id: 2,
      url_image: require("../../assets/images/processo.png"),
    },
    {
      id: 3,
      url_image: require("../../assets/images/caminhao.png"),
    },
    {
      id: 4,
      url_image: require("../../assets/images/fabrica3.png"),
    },
    {
      id: 5,
      url_image: require("../../assets/images/ninhomorango.png"),
    },
    {
      id: 6,
      url_image: require("../../assets/images/imagem_producao.jpg"),
    },
  ];
  return (
    <ContainerGalery id="galeria">
      <h2>Galeria</h2>

      <ImagesContent>
        {Images.map((image) => (
          <img key={image?.id} src={image.url_image} alt="Imagens da fábrica" />
        ))}
      </ImagesContent>
    </ContainerGalery>
  );
};
