import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "../page/Home";
import { Privacity } from "../page/Privacidade";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacidade" element={<Privacity />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
