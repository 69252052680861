import styled from "styled-components";

export const BannerContainer = styled.div`
  width: 100%;
  height: 100vh;
  top: 0;
  right: 0;

  @media screen and (max-width: 728px){
    height: 94vh;
  }
`;

export const BackgroundVideo = styled.video`
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  object-fit: cover;

  @media screen and (max-width: 728px) {
    width: 100%;
  }
`;
